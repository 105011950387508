@font-face {
    font-family: "Roboto-Light";
    src: url("./fonts/Roboto-Light.ttf");
}
@font-face {
    font-family: "Roboto-Thin";
    src: url("./fonts/Roboto-Thin.ttf");
}
@import "./componentes/navbar.scss";
@import "./componentes/loader.scss";
@import "./componentes/tabs.scss";
@import "./componentes/pop.scss";
@import "./componentes/slider.scss";
@import "./componentes/oxxo.scss";
@import "./componentes/spei.scss";
@import "./componentes/footer.scss";
@import "./services/servicios.scss";
@import "./componentes/services.scss";
body{
    background-color: #fff3e0;
}
::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ff8905; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
body{
    display: flex;
}

.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.material-icons-outlined.md-18 { font-size: 18px; }
.material-icons-outlined.md-24 { font-size: 24px; }
.material-icons-outlined.md-36 { font-size: 36px; }
.material-icons-outlined.md-48 { font-size: 48px; }

.d-block{
    display: block !important;
}
div#root {
    width: 100%;
}
.fa-1x{
    font-size: 20px;
}
.sp{
    padding: 0px !important;
}
.m-auto{
    margin: auto !important;
}
.ml-auto{
    margin-left: auto !important;
}
.pointer{
    cursor: pointer;
}
.m-auto-xs{
    margin: initial !important;
}
.ml-auto-md{
    margin-left: auto !important;
}
.centrar{
    display: flex;
    align-items: center;
}
.banner{
    background-image: url('../img/Compras-en-linea.jpg'), linear-gradient(#ebf6f7, #fff, #ebf6f7);
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0px;
    filter: opacity(0.3);
    position: absolute;
    z-index: -1;
}
.card, .btn {
    border-radius: 10px !important;
    overflow: hidden;
}
.card-store-sell
{
     border-radius: 10px;
     height: auto;
}

.orange-btn-h {
    background-color: #FFE8CB !important;
}

.btn-text-h{
    color:#080B17 !important;
    font-weight:700;
}
.strong-b{
    font-weight:700;
}
.strong-m{
    font-weight:600;
}
.strong-r{
    font-weight:400;
}
.card.horizontal {
    max-height: 250px;
}
.card-ins {
    height: 215px;
}
.name {
    font-size: 12px;
}

.s-price{
    transform:translateY(-10px);
}

.section-hr-lv{
    margin-left: 0px !important;
}
.stiky{
    position: sticky;
    top:0px;
    z-index: 99;
}
.stiky-f{
    position: sticky;
    top:18%;
}
.nav-s {
    z-index: 200;
}
.sm{
    margin: 0px !important;
}
.close,.pointer{
    cursor: pointer;
}
.brs{
    border-right: solid #e0e0e0 1px;
}
.bls{
    border-left: solid #e0e0e0 1px;
}
select {
    display: block !important;
}
// .oculto{
//     display: none !important;
// }
.circleSrvice{
    border-radius: 50%;
    border: solid;
    overflow: hidden;
    text-align: center;
    width: 150px;
    padding: 20px;
    height: 150px;
    box-shadow: 0px 10px 12px -2px;
    font-size: 32px;
}

.text-center{
    text-align: center;
}
.card-stacked>.card-content {
    padding: 25px !important;
    min-height: 150px;
}
.horizontal>.card-stacked>.card-content {
    padding: 20px;
    min-height: auto;
}
.negro{
    background-color: #000;
}
.card.horizontal .card-stacked {
    overflow-y: auto;
    overflow-x: hidden;
}
.banco {
    padding: 20px;
    color: #fff;
}
.clabe {
    border: solid;
    margin: 0px;
    width: max-content;
    margin: auto;
    padding: 0px 15px;
}
.alerta {
    position: fixed;
    right: -20%;
    top: 13%;
    background: #b72025;
    width: 20%;
    padding: 20px;
    border-radius: 5px;
    color: #000;
    box-shadow: -2px 10px 13px -8px #000;
    z-index: 10;
    animation: alerta 3s;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #ff8905;
    background-color: #fff;
    border-color: #fff #fff #ff8905;
    border-bottom: solid;
    font-weight: 700;
    background: whitesmoke;
}
#myTabContent {
    padding: 20px;
    border: solid 2px #dfdfdf;
}
.bsl{
    border-left: solid 2px #dfdfdf;
    border-right: solid 2px #dfdfdf;    
}
ul#myTab {
    padding-bottom: 10px;
    border: none;
}
.text-right{
    text-align: right;
}
.text-left{
    text-align: left;
}
label.file {
    height: 40px;
    position: relative;
}
label.file2 {
    height: 40px;
    position: relative !important;
    div{
        height: 50px;
    }
}

.btn-pop{
    padding-top:15px !important;
    padding-left:10px !important;
    
}

.n-p{
    padding-left:10px;
}
.file:before {
    content: 'Seleccionar archivo';
    position: absolute;
    top: 22%;
    left: -75px;
    background: #212121;
    padding: 10px 15px;
    color: #fff;
    border-radius: 7px;
    cursor: pointer;
    width: max-content;
}
.file2:before {
    content: 'Seleccionar archivo';
    position: absolute;
    top: 127%;
    left: 3px;
    background: #212121;
    padding: 10px 15px;
    color: #fff;
    border-radius: 7px;
    cursor: pointer;
    width: max-content;
}
.active {
    background-color: transparent !important;
}
.dataFile {
    height: 44px;
    line-height: 44px;
    text-align: right;
    width: 100%;
    margin: auto;
    padding: 0px 13px;
    border: solid 2px #ededed;
    font-size: 15px;
    cursor: pointer;
}
.mn {
    margin: 0px !important;
}
.bg-orange{
    background-color: #ff9800 !important;
}

.b-text {
    font-weight: 700;
    color: #ff9800;
}

.btn-style-banner {
    border-radius: 10px;
    background-color: #ff9800;
    font-size: 20px;
    font-weight: 700;
    text-transform: inherit;
}

.text-black,h1,h2,h3,h4,h5,h6{
    color: #212121 !important;
}
.text-orange,h1,h2,h3,h4,h5,h6{
    color: #ff9800 !important;
}
.cont-list{
    display: flex;
}
.tooltip{
    opacity: 0;
    position: absolute;
    // right: 15.5%;
    right: 104.5%;
    padding: 3px 10px;
    color: #fff;
    transition: all .5s;
    width: max-content;
}
.cont-list:hover>.tooltip, .opacity-1{
    opacity: 1 !important;
}
.tarjeta{
    position: relative;
}
.imprimir {
    position: relative;
    top: 0px;
    left: calc(22% - 116px);
}
.w-max{
    width: max-content;
    margin: 0px 20px;
}
.cupon{
    position: relative;
    span {
        position: absolute;
        margin: 0px !important;
        left: 10px;
        top: -10px;
        padding: 5px;
    }
    input {
        border: solid 1px #000 !important;
        border-radius: 2px !important;
    }
}
.text-primary{
    color:#ff9800 !important;
}
.dark-text{
    color:#000 !important;
}
.righ-text{
    text-align: right;
}
.logoPago>img{
    width: 80px;
    margin-left: 20px;
}
[type="radio"]:not(:checked)+span:before, [type="radio"]:not(:checked)+span:after {
    margin-top: 10px;
}
[type="radio"]:checked+span:before {
    margin-top: 10px;
}
[type="radio"]:checked+span:after {
    margin-top: 10px;
}
tr>td {
    border: 0px solid rgba(0,0,0,0.12) !important;
}
#curso-tab{
    position: relative;
    // top:10px;
}
.countAdd{
    transform: scale(1);
    animation: compra 1s;
}
.card-image>img {
    height: 185px;
    width: 100%;
}
.card-image.negro>img {
    height: 350px;
    width: 450px !important;
}
img.tumbail {
    height: 100% !important;
    object-fit: cover;
}
span.requi>p {
    margin: 0px;
}
.imgCart {
    height: 120px;
}
.bn{
    border: none;
}
span.correo {
    white-space: nowrap;
}
.tag{
    padding: 0px 7px;
    border-radius: 20px;
    font-size: 13px;
}
.card .card-image img {
    object-fit: contain;
    object-fit: cover;
    opacity: 0.8;
    
    
}

.pad-bread{
    padding-top:20px;
}

.icon-search{
    padding-left:15px;
    padding-top:5px;
    padding-bottom:5px;
}
.w-190{
    transform: translateY(-40px);
}

.w-80{
        width: 87% !important;
}

.pad-none{
    padding-left:0px;
    padding-right:opx;
    padding-top:20px;
    padding-bottom:20px;
}

.pad-import{
    padding-left:0px !important;
}

.height-nav {
  height:64px;    

}

.l-100 {
width:100%;    
}

.l-paddin {
margin-block-end:16em !important; 
}

.color-img {
    overflow: hidden;
    background: -webkit-linear-gradient(rgb(255, 152, 1), rgb(255, 152, 1)), url("");
    background: linear-gradient(rgb(255, 152, 1), rgb(255, 152, 1)), url("");
    border-radius: 10px;
}


.row2{
    display: flex;
}
.bg-orange.bages-cursos {
    position: absolute;
    left: 5%;
    top: 5%;
    z-index: 1;
    padding: 0px 10px;
    color: #fff;
    border-radius: 5px;
}
.logo-autor {
    position: absolute !important;
    z-index: 1;
    right: 5% !important;
    top: 5% !important;
    width: 40px;
    height: 40px;
    // background-color: #dfdfdf;
}
.carrito>span{
    position: relative;
    top:-4px;
}
.text-grey{
    color: #6c6f7b !important;
}
.outline {
    background: transparent !important;
    border: solid 2px #b72025 !important;
    color: #b72025 !important;
    font-weight: 700;
}
.outline:hover {
    background: #b72025 !important;
    color: #ffffff !important;
}
.scontenido{
    display: contents;
}
.requerimientos0{
    color: #6c6f7b !important;
    font-weight: 700;
    font-size: 20px;   
}
.pointer{
    cursor: pointer;
}
.descList{
    width: max-content;
    // margin: auto;
}
.hmc{
    height: max-content;
}
.bage {
    padding: 3px;
}
.New-inter{
    background-color: #CE93D8;
    color: #fff;
    padding: 5px 15px;
    border-radius: 5px;
}
.Expired-inter{
    background-color: #6e6e6e;
    color: #fff;
    padding: 5px 15px;
    border-radius: 5px;
}
.New{
    color: #CE93D8;
}
.Pending-inter,.Paused-inter{
    background-color: #FFF59D;
    color: #000;
    padding: 5px;
    border-radius: 5px;
}
.fw-700{
    font-weight: 700;
}
.fw-500{
    font-weight: 700;
}
.min-h-card-planes{
    min-height: 327px;
}
.min-h-card-planes-cursos{
    min-height: auto !important;
}
.hpx-105{
    height: 105px;
}
.Pending{
    color: #FFF59D;
}
.Checking-inter, .Process-inter, .Finished-inter{
    background-color: #64b5f6;
    color: #fff;
    padding: 5px 15px;
    border-radius: 5px;
}
.Checking{
    color: #64b5f6;
}
.Done-inter,.Paid-inter,.Active-inter{
    background-color: #81C784;
    color: #fff;
    padding: 5px 15px;
    border-radius: 5px;
}
.Done{
    color: #81C784;
}
.row{
    display: flex;
    flex-wrap: wrap;
}
.MuiAccordionDetails-root {
    display: block !important;
}
// .MuiPaper-root {
//     background-color: transparent !important;
// }
.alert-card{
    background-color: #ff9800 !important;
    color: #fff;
    box-shadow: -5px 5px 15px 0px #000 !important;
}
.resalta{
    font-weight: 700;
}
.d-flex{
    display: flex;
}
.text-overflow{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-center{
    text-align: center;
}
.card-title{
    width: 100%;
    margin: 0px;
    margin-bottom: 20px;
    padding: 10px;
}
.alert {
    width: 30%;
    text-align: center;
    position: absolute !important;
    right: 0%;
}

.bn-el{
    padding:20px;
}

.litype {
    color: #b72025 !important;
    border-bottom: solid 2px;
}
.terminos:hover,.terminos:hover>i{
    color: #b72025;
}
// STYLES PLANS
.logo-me {
    height: 100%;
    overflow: hidden;
}
.bg-plan{
    background-color: #f4f7f9;
}
.uppercase-letters{
    text-transform: uppercase;
}
.l-h-n{
    line-height: normal;
}
.marcado {
    border: solid 2px #b72025;
    background: rgba(183,32,37,0.1);
    color: #b72025;
    position: relative;
    padding: 0px 2px;
}
.marcado:after {
    content: '';
    width: 6px;
    height: 6px;
    background: #b72025;
    position: absolute;
    left: -4px;
    top: -4px;
    box-shadow: 85px 0px 0px 0px, 1px 35px 0px 0px, 85px 35px 0px 0px;
}
.card-stacked > .card-content {
    min-height: max-content;
}
.bottom{
    position: absolute;
    bottom: 10%;
}
.select-icon{
    display: grid;
    select{
        margin-left: 20px;
    }
}
.icon-float {
    position: absolute;
    top: 12px;
}
.breadcrumb {
    text-transform: capitalize;
}
.expand-card{
    min-height: 310px;
}
select {
    padding-left: 65px !important;
}
.me-circle>img{
    object-fit: cover !important;
    height: 100%;
    border-radius: 0px 90px 90px 0px !important;
}
.tabs .indicator {
    background-color: #000 !important;
}
.banner-plan {
    background: url(../img/curso1.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 20%;
}
.p-absolute {
    position: absolute;
}
.p-relative {
    position: relative;
}
$whiteTrans:rgba(255,255,255,.5);
$colors: (
    grey: #a8a9a9,
    yellow: #ffe0b2,
    white-trans:$whiteTrans,
    red-degradado: linear-gradient(0deg, rgba(183,32,37,1) 0%, rgba(244,83,88,1) 100%),
    red-light: #ffd4d5,
    blue-light: #f9fdff,
    orangeMe:#ff9200
);
.pop-card{
    max-height: 80vh;
    overflow: auto;
}
.MuiSnackbar-anchorOriginTopRight,.MuiSnackbar-anchorOriginTopRight{
    top: 110px !important;
}
.mr-auto{
    margin-right: auto;
}
.sidenav-overlay{
    z-index: 98 !important;
}
.d-none{
    display: none;
}
.d-block{
    display: none;
}

.r-m
{
      margin-bottom: 0;
}

.subtitle1 {
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.15px;
  
}
.subtitle2 {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
}

.subtitle3 {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1px;
}

.body1 {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.1px;
}

.body2 {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.1px;
}



.label1 {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.15px;
}


.link1 {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.25px;
}

.button-1 {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.25px;
}

.caption1 {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.4px;
}

.overline1 {
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 1.5px;
}

.time-icon {
    padding-left: 0px;
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
    color: #616161;
}

.text-card-cont {
    margin-top: 0;
    margin-left: 10px;
    width: 290px;
    /* font-weight: 400; */
}

.p-12{
    padding-left:15px !important;
    paddin-right:15px !important;
}

.etiq {
    width: 28px;
    height: 28px;
    background: #B72025;
    color: #ffff;
    border-radius: 5px;
    align-items: center;
    position: relative;
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
}

.title-p{
    height:80px;
}


.text-p{
    height:90px;
}

@media only screen
and (min-width: 320px)
and (max-width: 1187px)
{
.text-p{
    height:auto;

}
}

@media only screen
and (min-width: 1209px)
and (max-width: 1495px)
{
.text-p{
    height:110px;

}
}

@media only screen
and (min-width: 320px)
and (max-width: 720px)
{
.p-40{
    padding-top:30px!important;
    padding-right:0px !important;
    padding-left:0px !important;

}
}

@media only screen
and (min-width: 320px)
and (max-width: 1329px)
{
.l-paddin {
    margin-block-end: 19em !important;
}
}

.cash-marge {
    margin-bottom:0px !important;
}

.cont-card{
    height: 60px;
}


.note-icon {
    transform:translateY(10px);
    padding-right:10px;
}

.truncate-3 
{ 
     display: block;
     display: -webkit-box;
     -webkit-line-clamp: 3;
     -webkit-box-orient: vertical;
     overflow: hidden;
     text-overflow: ellipsis;
   }

   .truncate-2
{ 
     display: block;
     display: -webkit-box;
     -webkit-line-clamp: 2;
     -webkit-box-orient: vertical;
     overflow: hidden;
     text-overflow: ellipsis;
   }
   
   .input-field input {
  color: #080B17 !important;
  margin-top: 8px !important;
  padding: 0 8px !important;
  border: 1px solid !important;
  border-color: #080B17;
  font-size: 16px;
  border-radius: 10px !important;
}

.space-btn-pop {
   padding-top: 20px;
   padding-left: 10px;    
}


select {
  color: #080B17 !important;
  margin-top: 8px !important;
  padding: 0 8px !important;
  border: 1px solid !important;
  border-color: #080B17;
  font-size: 16px;
  border-radius: 10px !important;
}
select:focus, select:not(selector) {
  border: 1px solid #080B17;
  box-shadow: 0 0 0 0 #080B17 !important;
}


@media (min-width: 1200px) {
  label {
    font-size: 16px !important;
  }
}

.select-label {
  display: contents !important;
  margin-bottom: 8px !important;
}

.textarea-label {
  display: contents !important;
  margin-bottom: 8px !important;
}

.ql-container {
  color: #080B17 !important;
  padding: 0 8px !important;
  border: 1px solid !important;
  border-color: #080B17;
  font-size: 16px;
  border-radius: 0px 0px 10px 10px !important;
  font-size: 16px;
  font-family: inherit;
}
.ql-container:focus, .ql-container:not(selector) {
  border: 1px solid #080B17;
  box-shadow: 0 0 0 0 #080B17 !important;
}

.ql-toolbar.ql-snow {
  border: 1px solid #080B17 !important;
  display: none;
}
.ql-toolbar.ql-snow:focus, .ql-toolbar.ql-snow:not(selector) {
  display: block;
}


@each $button, $color in $colors {
    .text-#{$button} {
        --color: #{$color};
        color: #{$color} !important;
    }
    .bg-#{$button} {
        --background: #{$color};
        background: #{$color} !important    ;
    }
}
@for $i from 0 through 100 {
    .fz-#{$i}{
        font-size: 20px !important;
        font-weight:700;       
    }
    .w-px-#{$i}{
        width: $i * 1px;
    }
    .w-#{$i}{
        width: $i * 1%;
    }
    .hvh-#{$i}{
        height: $i * 1vh;
    }
    .hminvh-#{$i}{
        min-height: $i * 1vh;
    }
    .h-#{$i}{
        height: $i * 1%;
    }
    .hpx-#{$i}{
        height: $i * 1px;
    }
    .ml-#{$i}{
        margin-left: $i * 1px;
    }
    .mr-#{$i}{
        margin-right: $i * 1px;
    }
    .mt-#{$i}{
        margin-top: $i * 1px !important;
    }
    .mb-#{$i}{
        margin-bottom: $i * 1px !important;
    }
    .p-#{$i}{
        padding: $i * 1px;
    }
    .pr-#{$i}{
        padding-right: $i * 1px !important;
    }
    .pl-#{$i}{
        padding-left: $i * 1px !important;
    }
    .pt-#{$i}{
        padding-top: $i * 1px !important;
    }
    .pb-#{$i}{
        padding-bottom: $i * 1px !important;
    }
    .top-#{$i}{
        top: $i * 1px !important;
    }
    .left-#{$i}{
        left: $i * 1px !important;
    }
    .left--#{$i}{
        left: $i * -1px !important;
    }
    .bottom-#{$i}{
        bottom: $i * 1%;
    }

}
@keyframes alerta {
    0%   {right: -20%;}
    50%  {right: 0%;}
    100% {right: -20%;}
}
@keyframes compra {
    0%   {transform: scale(1);background-color: red;}
    50%  {transform: scale(1.4);background-color: black;}
    100% {transform: scale(1);background-color: red;}
}

@media only screen and (max-width: 900px) {
    .stiky-f {
        position: relative;
        top: 0px;
    }
    nav .brand-logo{
        left: 30% !important;
    }
    nav .sidenav-trigger {
        float: right !important;
    }
    .card.horizontal {
        max-height: 250px;
    }
    .price{
        text-align: center !important;
        margin-top: 20px;
    }
    .footer-copyright>div>div{
        display: block;
    }
    .movil{
        display: none;
    }
    .stiky-f{
        position: relative;
        top:0px;
    }
    .valign-wrapper {
        display: block !important;
        align-items: center;
    }
    .ml-auto-md{
        margin-left: initial !important;
    }
    .m-auto-xs{
        margin: auto !important;
    }
    .stiky-f {
        position: relative;
        top: 0px;
    }
    @for $i from 0 through 100 {
        .w-sm-#{$i}{
            width: $i * 1% !important;
        }
    }
    
    
}

/*=== SPACING ===*/
@media screen {
    .m-0         {margin: 0 !important;}
    .mt-0, .my-0 {margin-top: 0 !important;}
    .mr-0, .mx-0 {margin-right: 0 !important;}
    .mb-0, .my-0 {margin-bottom: 0 !important;}
    .ml-0, .mx-0 {margin-left: 0 !important;}
    .m-1         {margin: 0.25rem !important;}
    .mt-1, .my-1 {margin-top: 0.25rem !important;}
    .mr-1, .mx-1 {margin-right: 0.25rem !important;}
    .mb-1, .my-1 {margin-bottom: 0.25rem !important;}
    .ml-1, .mx-1 {margin-left: 0.25rem !important;}
    .m-2         {margin: 0.5rem !important;}
    .mt-2, .my-2 {margin-top: 0.5rem !important;}
    .mr-2, .mx-2 {margin-right: 0.5rem !important;}
    .mb-2, .my-2 {margin-bottom: 0.5rem !important;}
    .ml-2, .mx-2 {margin-left: 0.5rem !important;}
    .m-3         {margin: 1rem !important;}
    .mt-3, .my-3 {margin-top: 1rem !important;}
    .mr-3, .mx-3 {margin-right: 1rem !important;}
    .mb-3, .my-3 {margin-bottom: 1rem !important;}
    .ml-3, .mx-3 {margin-left: 1rem !important;}
    .m-4         {margin: 1.5rem !important;}
    .mt-4, .my-4 {margin-top: 1.5rem !important;}
    .mr-4, .mx-4 {margin-right: 1.5rem !important;}
    .mb-4, .my-4 {margin-bottom: 1.5rem !important;}
    .ml-4, .mx-4 {margin-left: 1.5rem !important;}
    .m-5         {margin: 3rem !important;}
    .mt-5, .my-5 {margin-top: 3rem !important;}
    .mr-5, .mx-5 {margin-right: 3rem !important;}
    .mb-5, .my-5 {margin-bottom: 3rem !important;}
    .ml-5, .mx-5 {margin-left: 3rem !important;}
    .m-6         {margin: 4rem !important;}
    .mt-6, .my-6 {margin-top: 4rem !important;}
    .mr-6, .mx-6 {margin-right: 4rem !important;}
    .mb-6, .my-6 {margin-bottom: 4rem !important;}
    .ml-6, .mx-6 {margin-left: 4rem !important;}
    .m-7         {margin: 5rem !important;}
    .mt-7, .my-7 {margin-top: 5rem !important;}
    .mr-7, .mx-7 {margin-right: 5rem !important;}
    .mb-7, .my-7 {margin-bottom: 5rem !important;}
    .ml-7, .mx-7 {margin-left: 5rem !important;}
    .m-8         {margin: 6rem !important;}
    .mt-8, .my-8 {margin-top: 6rem !important;}
    .mr-8, .mx-8 {margin-right: 6rem !important;}
    .mb-8, .my-8 {margin-bottom: 6rem !important;}
    .ml-8, .mx-8 {margin-left: 6rem !important;}

    .p-0         {padding: 0 !important;}
    .pt-0, .py-0 {padding-top: 0 !important;}
    .pr-0, .px-0 {padding-right: 0 !important;}
    .pb-0, .py-0 {padding-bottom: 0 !important;}
    .pl-0, .px-0 {padding-left: 0 !important;}
    .p-1         {padding: 0.25rem !important;}
    .pt-1, .py-1 {padding-top: 0.25rem !important;}
    .pr-1, .px-1 {padding-right: 0.25rem !important;}
    .pb-1, .py-1 {padding-bottom: 0.25rem !important;}
    .pl-1, .px-1 {padding-left: 0.25rem !important;}
    .p-2         {padding: 0.5rem !important;}
    .pt-2, .py-2 {padding-top: 0.5rem !important;}
    .pr-2, .px-2 {padding-right: 0.5rem !important;}
    .pb-2, .py-2 {padding-bottom: 0.5rem !important;}
    .pl-2, .px-2 {padding-left: 0.5rem !important;}
    .p-3         {padding: 1rem !important;}
    .pt-3, .py-3 {padding-top: 1rem !important;}
    .pr-3, .px-3 {padding-right: 1rem !important;}
    .pb-3, .py-3 {padding-bottom: 1rem !important;}
    .pl-3, .px-3 {padding-left: 1rem !important;}
    .p-4         {padding: 1.5rem !important;}
    .pt-4, .py-4 {padding-top: 1.5rem !important;}
    .pr-4, .px-4 {padding-right: 1.5rem !important;}
    .pb-4, .py-4 {padding-bottom: 1.5rem !important;}
    .pl-4, .px-4 {padding-left: 1.5rem !important;}
    .p-5         {padding: 3rem !important;}
    .pt-5, .py-5 {padding-top: 3rem !important;}
    .pr-5, .px-5 {padding-right: 3rem !important;}
    .pb-5, .py-5 {padding-bottom: 3rem !important;}
    .pl-5, .px-5 {padding-left: 3rem !important;}

    .p-6         {padding: 4rem !important;}
    .pt-6, .py-6 {padding-top: 4rem !important;}
    .pr-6, .px-6 {padding-right: 4rem !important;}
    .pb-6, .py-6 {padding-bottom: 4rem !important;}
    .pl-6, .px-6 {padding-left: 4rem !important;}

    .p-7         {padding: 5rem !important;}
    .pt-7, .py-7 {padding-top: 5rem !important;}
    .pr-7, .px-7 {padding-right: 5rem !important;}
    .pb-7, .py-7 {padding-bottom: 5rem !important;}
    .pl-7, .px-7 {padding-left: 5rem !important;}

    .p-8         {padding: 6rem !important;}
    .pt-8, .py-8 {padding-top: 6rem !important;}
    .pr-8, .px-8 {padding-right: 6rem !important;}
    .pb-8, .py-8 {padding-bottom: 6rem !important;}
    .pl-8, .px-8 {padding-left: 6rem !important;}


    .m-n1          {margin: -0.25rem !important;}
    .mt-n1, .my-n1 {margin-top: -0.25rem !important;}
    .mr-n1, .mx-n1 {margin-right: -0.25rem !important;}
    .mb-n1, .my-n1 {margin-bottom: -0.25rem !important;}
    .ml-n1, .mx-n1 {margin-left: -0.25rem !important;}
    .m-n2          {margin: -0.5rem !important;}
    .mt-n2, .my-n2 {margin-top: -0.5rem !important;}
    .mr-n2, .mx-n2 {margin-right: -0.5rem !important;}
    .mb-n2, .my-n2 {margin-bottom: -0.5rem !important;}
    .ml-n2, .mx-n2 {margin-left: -0.5rem !important;}
    .m-n3          {margin: -1rem !important;}
    .mt-n3, .my-n3 {margin-top: -1rem !important;}
    .mr-n3, .mx-n3 {margin-right: -1rem !important;}
    .mb-n3, .my-n3 {margin-bottom: -1rem !important;}
    .ml-n3, .mx-n3 {margin-left: -1rem !important;}
    .m-n4          {margin: -1.5rem !important;}
    .mt-n4, .my-n4 {margin-top: -1.5rem !important;}
    .mr-n4, .mx-n4 {margin-right: -1.5rem !important;}
    .mb-n4, .my-n4 {margin-bottom: -1.5rem !important;}
    .ml-n4, .mx-n4 {margin-left: -1.5rem !important;}
    .m-n5          {margin: -3rem !important;}
    .mt-n5, .my-n5 {margin-top: -3rem !important;}
    .mr-n5, .mx-n5 {margin-right: -3rem !important;}
    .mb-n5, .my-n5 {margin-bottom: -3rem !important;}
    .ml-n5, .mx-n5 {margin-left: -3rem !important;}
    .m-n6          {margin: -4rem !important;}
    .mt-n6, .my-n6 {margin-top: -4rem !important;}
    .mr-n6, .mx-n6 {margin-right: -4rem !important;}
    .mb-n6, .my-n6 {margin-bottom: -4rem !important;}
    .ml-n6, .mx-n6 {margin-left: -4rem !important;}
    .m-n7          {margin: -5rem !important;}
    .mt-n7, .my-n7 {margin-top: -5rem !important;}
    .mr-n7, .mx-n7 {margin-right: -5rem !important;}
    .mb-n7, .my-n7 {margin-bottom: -5rem !important;}
    .ml-n7, .mx-n7 {margin-left: -5rem !important;}
    .m-n8          {margin: -6rem !important;}
    .mt-n8, .my-n8 {margin-top: -6rem !important;}
    .mr-n8, .mx-n8 {margin-right: -6rem !important;}
    .mb-n8, .my-n8 {margin-bottom: -6rem !important;}
    .ml-n8, .mx-n8 {margin-left: -6rem !important;}

    .m-auto        {margin: auto !important;}
    .mt-auto, .my-auto {margin-top: auto !important;}
    .mr-auto, .mx-auto {margin-right: auto !important;}
    .mb-auto, .my-auto {margin-bottom: auto !important;}
    .ml-auto, .mx-auto {margin-left: auto !important;}
}
/*=== SPACING END ===*/


/*=== SIZING ===*/
.w-25{width:25%!important}
.w-50{width:50%!important}
.w-75{width:75%!important}
.w-100{width:100%!important}
.w-auto{width:auto!important}
.h-25{height:25%!important}
.h-50{height:50%!important}
.h-75{height:75%!important}
.h-100{height:100%!important}
.h-auto{height:auto!important}
.mw-100{max-width:100%!important}
.mh-100{max-height:100%!important}
.min-vw-100{min-width:100vw!important}
.min-vh-100{min-height:100vh!important}
.vw-100{width:100vw!important}
.vh-100{height:100vh!important}
/*=== SPACING END ===*/